<template>
    <div>
        <template v-if="detailId">
            <div class="page-title">编辑问卷</div>
        </template>
        <template v-else>
            <div class="page-title">新增问卷</div>
        </template>

        <a-spin :spinning="loading">

            <pay-box class="qa-box" title="基础设置">

                <div>
                    <span class="mr-4">是否强制预留授权用户手机</span>
                    <a-radio-group v-model="detail.need_phone">
                        <a-radio :value="1">预留</a-radio>
                        <a-radio :value="0">不预留</a-radio>
                    </a-radio-group>
                </div>


            </pay-box>

            <pay-box class="qa-box" title="问卷标题">
                <input v-model="detail.title" class="qa-text title-area" placeholder="请输入不超过100字的标题"
                       :maxLength="100"/>
                <span class="text-count">{{ detail.title.length }}/100</span>
            </pay-box>
            <pay-box class="qa-box" title="问卷描述">
                <a-textarea class="qa-text"
                            v-model="detail.summary"
                            placeholder="请输入问卷描述信息，描述信息主要为答题者表述本问卷的调查目的或对答题人的要求等信息"
                            :maxLength="200"
                            autoSize></a-textarea>
                <span class="text-count">{{ detail.summary.length }}/200</span>
            </pay-box>
            <pay-box title="问卷内容">
                <template v-for="(question,qk) in detail.questions">
                    <div :key="qk" v-if="!question.delete">
                        <question :no="getNo(qk)" class="question" v-model="detail.questions[qk]"
                                  @delete="delQuestion(qk)"/>
                    </div>
                </template>

                <div class="new-q flex flex-center" @click="addQuestion">
                    <div>点击继续添加问题</div>
                    <img src="/assets/icons/new-question-finger.png" alt="">
                </div>
            </pay-box>

            <a-affix :offset-bottom="0">
                <div class="foot-btns">
                    <button class="btn mr-8" @click="preview">预览</button>
                    <button class="btn mr-8" @click="saveChange()">保存</button>
                    <button class="btn btn-white" @click="back">返回列表</button>
                </div>
            </a-affix>
        </a-spin>

        <img v-if="miniCode" @load="qrLoad"
             class="preload-img"
             :src="miniCode + '&t=' + t "
             alt="">

        <a-modal v-model="showMiniCodeModal" :footer="null" width="406px">
            <div slot="title" class="text-center" v-if="detail">{{ detail.title }}</div>
            <div class="mini-qrcode-container flex flex-center">
                <small>扫码预览问卷</small>
                <br>
                <img v-if="miniCode" :src="miniCode" alt="">
                <br>
                <small>预览问卷仅查看不可填写</small>
            </div>
        </a-modal>

    </div>
</template>

<script>
import Question from "@/views/AST/Q&A/Com/Question.vue";
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import api from "@/repo/api";

export default {
    name: "Detail",
    components: {PayBox, Question},
    data() {
        return {
            detail: {
                title: "",
                summary: "",
                questions: [],
                need_phone: 0,
            },
            detailId: 0,
            loading: false,
            showMiniCodeModal: false,
            miniCode: undefined,
            t: 0,
        }
    },
    mounted() {
        this.detailId = this.$route.params.id;
        console.log(2333,this.detailId);
        if (this.detailId) {
            this.fetchDetail()
        } else {
            this.newQa()
        }
    },
    methods: {
        qrLoad() {
            this.$loading.hide()
            this.showMiniCodeModal = true
        },
        activeMiniCode() {

            this.$loading.show()
            this.t++;
            setTimeout(() => {
                this.miniCode = this.$qrcode(
                    "packagesC/questionnaireDetail/questionnaireDetail?id=" + this.detail.id,
                    `问卷小程序码-${this.detail.id}`
                );
            }, 50)
        },
        fetchDetail() {
            this.loading = true

            api.get('/ast-qa/detail', {id: this.detailId}, res => {
                this.loading = false

                this.detail = res.data

                this.$forceUpdate()
            })
        },
        preview() {
            this.saveChange(true, () => {
                this.fetchDetail()
                this.activeMiniCode()
            })
        },
        saveChange(notBack, success) {

            this.$loading.show()

            api.post('/ast-qa/sync', this.detail, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code !== 0) return;

                this.detail.id = res.data.id
                this.detailId = res.data.id

                if ((!notBack)) {
                    this.$router.push('/ast/qas')
                }

                if (success) success()

            })

        },
        newQa() {
            this.detail = {
                title: "",
                summary: "",
                questions: [{}, {}, {}]
            }
        },
        addQuestion() {
            this.detail.questions.push({})
        },
        back() {
            this.$router.back()
        },
        delQuestion(idx) {
            let question = this.detail.questions[idx]

            if (question.id) {

                question.delete = 1

                this.$forceUpdate()
                return
            }

            this.detail.questions.splice(idx, 1)
        },
        getNo(qk) {

            let no = 1

            for (const questionsKey in this.detail.questions) {

                if (questionsKey >= qk) return no

                if (!this.detail.questions[questionsKey].delete) no++;
            }

            return no;
        }
    }
}
</script>

<style scoped lang="less">
.question {
    margin-bottom: 24px;
}

.new-q {
    width: 544px;
    height: 102px;
    background: #F7F7F7;
    margin-left: 30px;

    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    flex-direction: column;
    cursor: pointer;

    img {
        margin-top: 4px;
        width: 30px;
        height: 30px;
    }
}

.qa-box /deep/ .ast-pay-body {
    line-height: 0;
    position: relative;
}

.qa-text {
    resize: none;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    width: 100%;
    padding: 0;
    height: 20px;
    min-height: 20px;
    box-shadow: none !important;
}

.foot-btns {
    background-color: white;
    padding-bottom: 30px;
    padding-top: 30px;
}

.text-count {
    position: absolute;
    right: 24px;
    bottom: 37px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

.mini-qrcode-container {
    flex-direction: column;

    img {
        width: 256px;
        height: 256px;
    }
}
</style>